import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

interface Coordinates {
  lat: number;
  lng: number;
}

export const Map: React.FC<Coordinates> = ({ lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAn1JrBGNYj_1wH0LoSOrZ7CHBVvRpl5xU',
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={{ lat, lng }}
      zoom={17}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        scrollwheel: true,
        rotateControl: false,
        mapTypeControl: false,
      }}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  ) : (
    <></>
  );
};
