import { ChangeEvent, TextareaHTMLAttributes } from 'react';

import { ErrorMessage } from './ErrorMessage';

import styles from './Textarea.module.scss';

interface TextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  name: string;
  label: string;
  error?: string;
  onChange: (value: string, e: ChangeEvent) => void;
}

export const Textarea: React.FC<TextareaProps> = ({
  label,
  name,
  onChange,
  error,
  ...rest
}) => {
  return (
    <section className={styles['textarea-section']}>
      <textarea
        id={name}
        name={name}
        className={styles['textarea-field']}
        onChange={e => onChange(e.target.value, e)}
        {...rest}
      />
      {error && <ErrorMessage error={error} />}
    </section>
  );
};
