import styles from './NossosDiferenciais.module.scss';

import { TitleSection } from '../TitleSection';
import { CardDiferenciais } from '../CardDiferenciais';

import medidaLogo from '../../assets/icons-diferenciais/icon-medida.svg';
import qualidadeLogo from '../../assets/icons-diferenciais/icon-qualidade.svg';
import dedicacaoLogo from '../../assets/icons-diferenciais/icon-dedicacao.svg';
import processosLogo from '../../assets/icons-diferenciais/icon-processos.svg';
import segurancaLogo from '../../assets/icons-diferenciais/icon-seguranca.svg';
import eficienciaLogo from '../../assets/icons-diferenciais/icon-eficiencia.svg';

export const NossosDiferenciais = () => {
  return (
    <section id="nossos-diferenciais" className="bg-gray100">
      <div className="center-container">
        <div className={styles['diferenciais-container']}>
          <TitleSection title={'Nossos Diferenciais'} />
          <div className={styles['diferenciais-grid']}>
            <CardDiferenciais
              logo={{
                url: qualidadeLogo,
                alt: 'Qualidade',
              }}
              title="Qualidade"
              description="Utilizamos métodos diferenciados e proporcionamos aos nossos clientes o conforto e a satisfação que merecem a cada projeto."
            />
            <CardDiferenciais
              logo={{
                url: dedicacaoLogo,
                alt: 'Dedicação',
              }}
              title="Dedicação"
              description="Trabalhamos oferecendo respeito e dedicação para com nossos clientes. Contudo, moral e responsabilidade são padrões do nosso cotidiano dentro da empresa."
            />
            <CardDiferenciais
              logo={{
                url: eficienciaLogo,
                alt: 'Eficiência',
              }}
              title="Eficiência"
              description="Temos uma política de foco em resultados, atendendo às demandas e aos interesses de nossos clientes para que as soluções ocorram no prazo e com a eficiência desejada."
            />
            <CardDiferenciais
              logo={{
                url: processosLogo,
                alt: 'Otimização de Processos',
              }}
              title="Otimização de Processos"
              description="Agregue controle de forma assertiva no seu Negócio!
              Através de um de nossos serviços, tornando o seu empreendimento mais Produtivo e Lucrativo."
            />
            <CardDiferenciais
              logo={{
                url: segurancaLogo,
                alt: 'Segurança',
              }}
              title="Segurança"
              description="Trabalhamos conforme a sua necessidade, seja no Banco de Dados em Nuvem ou Local, a segurança de seus Dados, é a nossa Prioridade."
            />
            <CardDiferenciais
              logo={{
                url: medidaLogo,
                alt: 'Sob Medida',
              }}
              title="Sob Medida"
              description="A ALLCOM SISTEMAS, possui o software ideal para o seu estabelecimento, mas se mesmo assim não lhe atender.... iremos desenvolver sob medida para você."
            />
          </div>
        </div>
      </div>
    </section>
  );
};
