import classNames from 'classnames';
import logo from '../../assets/allcom-logo-horizontal.svg';

import styles from './lgpd.module.scss';

export const LGPDComponent = () => {
  return (
    <section className={classNames(styles['container'], 'center-container')}>
      <div className={styles['title-container']}>
        <img src={logo} alt="Allcom Sistemas Logo" />
        <h2 className="heading3"> Lei Geral de Proteção de Dados</h2>
      </div>

      <div className={styles['lgpd-container']}>
        <div className={styles['lgpd-content']}>
          <p className="body1">
            A Lei n° 13.709/2018, dispõe sobre a proteção dos direitos
            fundamentais de liberdade e privacidade de nossos dados pessoais.
          </p>
          <p className="body1">
            A Allcom Sistemas já está preparada para esta importante mudança na
            legislação brasileira, comprometida a seguir os princípios que
            garantem o correto manuseio, guarda e utilização dos dados
            fornecidos nos termos previstos na lei.
          </p>
          <p className="body1">
            É uma tendência mundial, já vivenciada em alguns países da Europa, à
            exemplo da Espanha, onde a LGPD já vigora a mais tempo e são rígidas
            em seu cumprimento.
          </p>
        </div>
      </div>
    </section>
  );
};
