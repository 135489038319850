import { Layout } from '../components/Layout';

import { Footer } from '../components/Footer';
import { Inicio } from '../components/sections/Inicio';
import { Contato } from '../components/sections/Contato';
import { Depoimentos } from '../components/sections/Depoimentos';
import { NossasSolucoes } from '../components/sections/NossasSolucoes';
import { NossosDiferenciais } from '../components/sections/NossosDiferenciais';

export const Home = () => {
  return (
    <Layout>
      <Inicio />
      <NossasSolucoes />
      <NossosDiferenciais />
      <Depoimentos />
      <Contato />
      <Footer />
    </Layout>
  );
};
