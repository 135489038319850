import styles from './Carrousel.module.scss';

import Slider from 'react-slick';
import {
  NextArrow,
  PrevArrow,
  responsive,
} from '../constants/constants-carrousel';

interface CarouselProps {
  children: React.ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
  return (
    <div className={styles['carousel-slide-container']}>
      <Slider
        autoplay={true}
        pauseOnHover={true}
        infinite={true}
        speed={800}
        slidesPerRow={2}
        slidesToShow={3}
        slidesToScroll={1}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        {...responsive}
      >
        {children}
      </Slider>
    </div>
  );
};
