export type ArrayBufferLike = ArrayBufferTypes[keyof ArrayBufferTypes];

export const readFileContents = (file: File) =>
  new Promise<ArrayBufferLike>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);

    fileReader.onload = event => {
      if (!event.target) {
        reject(new Error(`Failed to read file contents`));

        return;
      }

      resolve(event.target?.result as ArrayBufferLike);
    };
  });
