import classNames from 'classnames';

import {
  FaEnvelope,
  FaWhatsapp,
  FaFacebookSquare,
  FaLinkedin,
  FaInstagram,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Map } from '../components/Map';

import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <>
      <section id="footer" className={styles['footer-section']}>
        <div className="center-container">
          <div className={styles['footer-container']}>
            <div className={styles['footer-item']}>
              <h6 className="heading6 cyan400">Conecte-se conosco</h6>

              <a
                href="mailto:contato@allcomsistemas.com.br"
                className="text-light hover-cyan400"
                title="Clique para enviar uma mensagem no whatsapp."
              >
                <p className="paragraphsNormal hover-cyan400">
                  <FaEnvelope size={24} />
                  contato@allcomsistemas.com.br
                </p>
              </a>

              <a
                href="https://api.whatsapp.com/send?l=pt_BR&phone=5514997416193&text=Olá, estou entrando em contato pelo site."
                target="_blank"
                rel="noreferrer"
                className="text-light hover-cyan400"
                title="Clique para enviar uma mensagem no whatsapp."
              >
                <p className="hover-cyan400">
                  <FaWhatsapp size={24} />
                  (14) 99741 - 6193
                </p>
              </a>
              <a
                href="https://api.whatsapp.com/send?l=pt_BR&phone=551441411395&text=Olá, estou entrando em contato pelo site."
                target="_blank"
                rel="noreferrer"
                className="text-light hover-cyan400"
                title="Clique para enviar uma mensagem no whatsapp."
              >
                <p className="hover-cyan400">
                  <FaWhatsapp size={24} />
                  (14) 4141 - 1395
                </p>
              </a>

              <div className={styles['social-media-icons']}>
                <a
                  href="https://www.linkedin.com/company/allcom-sistemas/"
                  target="_blank"
                  rel="noreferrer"
                  title="linkedin"
                >
                  <FaLinkedin size={30} className="gray50" />
                </a>
                <a
                  href="https://www.facebook.com/allcomsistemas"
                  target="_blank"
                  rel="noreferrer"
                  title="facebook"
                >
                  <FaFacebookSquare size={30} className="gray50" />
                </a>
                <a
                  href="https://www.instagram.com/allcomsistemas/"
                  target="_blank"
                  rel="noreferrer"
                  title="instagram"
                >
                  <FaInstagram size={30} className="gray50" />
                </a>
              </div>
            </div>

            <div className={styles['footer-item']}>
              <h6 className="heading6 cyan400">Navegação</h6>
              <ul className={styles['footer-navigation']}>
                <li>
                  <a href="/#inicio" className="paragraphsNormal hover-cyan400">
                    Início
                  </a>
                </li>
                <li>
                  <a
                    href="/#nossas-solucoes"
                    className="paragraphsNormal hover-cyan400"
                  >
                    Soluções
                  </a>
                </li>
                <li>
                  <a
                    href="/#nossos-diferenciais"
                    className="paragraphsNormal hover-cyan400"
                  >
                    Diferenciais
                  </a>
                </li>
                <li>
                  <a
                    href="/#contato"
                    className="paragraphsNormal hover-cyan400"
                  >
                    Contato
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://blog.allcomsistemas.com.br/"
                    className="paragraphsNormal hover-cyan400"
                  >
                    Blog
                  </a>
                </li>
                <Link
                  to="/trabalhe-conosco"
                  className="paragraphsNormal hover-cyan400"
                >
                  Trabalhe Conosco
                </Link>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://atualizacao.allcomsistemas.com.br"
                  className="paragraphsNormal hover-cyan400"
                >
                  Suporte
                </a>
              </ul>
            </div>

            <div className={styles['footer-item']}>
              <h6 className="heading6 cyan400">Onde estamos</h6>
              <p className="paragraphsNormal">
                Rio Branco, 5-38 - Sala 53 - Centro, Bauru - SP, 17010-190
              </p>
              <a
                className={classNames(styles['see-route-link'], 'body2')}
                target="_blank"
                rel="noreferrer"
                href={`https://www.google.com/maps/place/Allcom+Sistemas/@-22.325261,-49.0654842,17z/data=!3m1!4b1!4m5!3m4!1s0x94bf67a18f744c8b:0x528ba6286e0673e!8m2!3d-22.325261!4d-49.0632902`}
              >
                Ver rotas
              </a>
            </div>

            <div className={styles['map-container']}>
              <Map lat={-22.3203203} lng={-49.0727825} />
            </div>
          </div>
        </div>
      </section>

      <div className={styles['policy-container']}>
        <div className="center-container">
          <p className="caption1 textLight">
            © 2017 - 2022, Allcom Sistemas - Todos os direitos reservados.
          </p>

          <div className={styles['policy-content']}>
            <Link to="/lgpd" className="caption1 textLight">
              LGPD
            </Link>

            <Link to="/politica-privacidade" className="caption1 textLight">
              Política de Privacidade
            </Link>

            <Link to="/termos-de-uso" className="caption1 textLight">
              Termos de Uso
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
