import styles from './TermosDeUso.module.scss';

import logo from '../../assets/allcom-logo-horizontal.svg';

export const TermosDeUsoComponent = () => {
  return (
    <section className="center-container">
      <div className={styles['title-container']}>
        <img src={logo} alt="Allcom Sistemas Logo" />
        <h2 className="heading3">Termos de Uso</h2>
      </div>

      <div className={styles['use-terms-container']}>
        <p className="subheading"> Bem-vindo à Allcom Sistemas!</p>

        <p className="body1">Termos e Condições</p>
        <p className="body1">
          Estes termos e condições descrevem as regras e regulamentos para uso
          do site da AC Sistemas Tecnologia Eireli, localizado na Av. Nações
          Unidas, 17-17 sala 508 Bauru/SP.
        </p>
        <p className="body1">
          Ao acessar este site, assumimos que você aceita estes termos e
          condições. Não continue a usar a Allcom Sistemas caso não concorde em
          aceitar todos os termos e condições indicados nesta página.
        </p>
        <p className="body1">
          Ao acessar este site, assumimos que você aceita estes termos e
          condições. Não continue a usar a Allcom Sistemas caso não concorde em
          aceitar todos os termos e condições indicados nesta página.
        </p>
        <p className="body1">
          Cookies: O site usa cookies para ajudar a personalizar sua experiência
          online. Ao acessar a Allcom Sistemas, você concorda em utilizar os
          cookies necessários. Um cookie é um arquivo de texto que é colocado em
          seu disco rígido por um servidor de página da web. Os cookies não
          podem ser usados para executar programas ou entregar vírus ao seu
          computador. Os cookies são atribuídos exclusivamente a você e só podem
          ser lidos por um servidor da web no domínio que emitiu o cookie para
          você. Podemos usar cookies para coletar, armazenar e rastrear
          informações para fins estatísticos ou de marketing para operar nosso
          site. Você tem a capacidade de aceitar ou recusar Cookies opcionais.
        </p>
        <p className="body1">
          Existem alguns Cookies obrigatórios que são necessários para o
          funcionamento do nosso site. Esses cookies não requerem seu
          consentimento, pois sempre funcionam. Lembre-se de que, ao aceitar os
          cookies necessários, você também aceita cookies de terceiros, que
          podem ser usados por meio de serviços fornecidos por terceiros se você
          usar esses serviços em nosso site, por exemplo, uma janela de exibição
          de vídeo fornecida por terceiros e integrada em nosso site.
        </p>
        <p className="body1">
          Licença: Salvo disposição em contrário, a AC Sistemas Tecnologia
          Eireli e/ou seus licenciadores detêm os direitos de propriedade
          intelectual de todo o material da Allcom Sistemas.
        </p>
        <p className="body1">
          Todos os direitos de propriedade intelectual são reservados. Você pode
          acessá-lo da Allcom Sistemas para seu uso pessoal sujeito às
          restrições estabelecidas nestes termos e condições. Você não deve:
          Copiar ou republicar material da Allcom Sistemas, vender, alugar ou
          sublicenciar material da Allcom Sistemas Reproduzir, duplicar ou
          copiar material da Allcom Sistemas Redistribuir conteúdo da Allcom
          Sistemas Este Contrato terá início na presente data.
        </p>
        <p className="body1">
          Partes deste site oferecem aos usuários a oportunidade de postar e
          trocar opiniões e informações em determinadas áreas do site. A AC
          Sistemas Tecnologia Eireli não filtra, edita, publica ou revisa
          Comentários antes de sua presença no site. Os comentários não refletem
          as visões e opiniões da AC Sistemas Tecnologia Eireli, seus agentes
          e/ou afiliadas.
        </p>
        <p className="body1">
          Os comentários refletem os pontos de vista e opiniões da pessoa que
          publica seus pontos de vista e opiniões. Na medida permitida pelas
          leis aplicáveis, a AC Sistemas Tecnologia Eireli não será responsável
          pelos Comentários ou qualquer responsabilidade, danos ou despesas
          causados e/ou sofridos como resultado de qualquer uso e/ou publicação
          e/ou aparência do Comentários neste site.
        </p>
        <p className="body1">
          A AC Sistemas Tecnologia Eireli se reserva o direito de monitorar
          todos os Comentários e remover quaisquer Comentários que possam ser
          considerados inadequados, ofensivos ou que causem violação destes
          Termos e Condições.
        </p>
        <p className="body1">
          Você garante e declara que: Você tem o direito de postar os
          Comentários em nosso site e tem todas as licenças e consentimentos
          necessários para fazê-lo; Os Comentários não invadem nenhum direito de
          propriedade intelectual, incluindo, sem limitação, direitos autorais,
          patentes ou marcas registradas de terceiros.
        </p>

        <p className="body1">
          Os Comentários não contêm nenhum material difamatório, calunioso,
          ofensivo, indecente ou ilegal, o que é uma invasão de privacidade. Os
          Comentários não serão usados para solicitar ou promover negócios ou
          costumes ou apresentar atividades comerciais ou atividades ilegais.
        </p>

        <p className="body1">
          Você concede à AC Sistemas Tecnologia Eireli uma licença não exclusiva
          para usar, reproduzir, editar e autorizar outros a usar, reproduzir e
          editar quaisquer de seus Comentários em todas e quaisquer formas,
          formatos ou mídias.
        </p>
      </div>
    </section>
  );
};
