import { ChangeEvent, InputHTMLAttributes } from 'react';
import styles from './InputFile.module.scss';

interface InputFileProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label: string;
  onChange: (
    value: FileList | null,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
}

export const InputFile = ({
  label,
  name,
  onChange,
  ...rest
}: InputFileProps) => {
  return (
    <section className={styles['input-file-section']}>
      <label htmlFor={name}>
        {label}
        <input
          type="file"
          id={name}
          name={name}
          onChange={e => onChange(e.target.files, e)}
          {...rest}
        />
      </label>
    </section>
  );
};
