import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Home } from './pages/Home';
import { LGPD } from './pages/LGPD';
import { NotFound } from './pages/NotFound';
import { TermosDeUso } from './pages/TermosDeUso';
import { DetalhesVaga } from './pages/DetalhesVaga';
import { TrabalheConosco } from './pages/TrabalheConosco';
import { PoliticaPrivacidade } from './pages/PoliticaPrivacidade';

export const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
        <Route path="/detalhes-vaga" element={<DetalhesVaga />} />
        <Route path="/lgpd" element={<LGPD />} />
        <Route path="/termos-de-uso" element={<TermosDeUso />} />
        <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
