import { Layout } from '../components/Layout';
import { NotFoundComponent } from '../components/sections/NotFound';

export const NotFound = () => {
  return (
    <Layout>
      <NotFoundComponent />
    </Layout>
  );
};
