import { Footer } from '../components/Footer';
import { Layout } from '../components/Layout';
import { TermosDeUsoComponent } from '../components/sections/TermosDeUso';

export const TermosDeUso = () => {
  window.scrollTo(0, 0);

  return (
    <Layout>
      <TermosDeUsoComponent />
      <Footer />
    </Layout>
  );
};
