import { FormEvent, useEffect, useState } from 'react';

import classNames from 'classnames';
import { toast } from 'react-toastify';

import { InputFile } from '../InputFile';

import { readFileContents } from '../../functions/readFileContents';

import image from '../../assets/vagas-image.png';

import styles from './TrabalheConosco.module.scss';
import { CircleNotch } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { Input } from '../Input';

export const TrabalheConoscoComponent = () => {
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [curriculo, setCurriculo] = useState<File | null>(null);

  const [nome, setNome] = useState('');

  useEffect(() => window.scrollTo(0, 0), []);

  const UPLOAD_FILE_URL =
    'https://script.google.com/macros/s/AKfycbzoMmG810zS4twEpsjftoqmOwgRHBwPEmhSDiodkq6xjoyAMLMOzySgI9zsQEgLVJg/exec';

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSendingForm(true);

    if (nome === '') {
      toast.warn('Por favor nos informe seu nome.', {
        position: 'bottom-right',
        pauseOnHover: false,
        theme: 'colored',
      });
    }

    try {
      if (curriculo === null) {
        toast.warn('Anexe o currículo para enviar.', {
          position: 'bottom-right',
          pauseOnHover: false,
          theme: 'colored',
        });
        return;
      }

      const fileContents = await readFileContents(curriculo);

      const params = new URLSearchParams({
        filename: `${curriculo.name}-CURRICULO-${nome}`,
        mimeType: curriculo.type,
      });

      const uploadFileResponse = await fetch(`${UPLOAD_FILE_URL}?${params}`, {
        method: 'POST',
        body: JSON.stringify([...new Int8Array(fileContents)]),
      });

      if (uploadFileResponse.status !== 200) {
        const text = await uploadFileResponse.text();
        throw new Error(`Fetch Error: Failed to upload a file. ${text}`);
      }

      toast.success('Currículo enviado com sucesso!', {
        position: 'bottom-right',
        pauseOnHover: false,
        theme: 'colored',
      });

      setCurriculo(null);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao enviar formulário.', {
        position: 'bottom-right',
        pauseOnHover: false,
        theme: 'colored',
      });
    } finally {
      setIsSendingForm(false);
    }
  };

  return (
    <div className="center-container">
      <div className={styles['container-hero']}>
        <div className={styles['paragraphs-container']}>
          <h2 className="heading2">Trabalhe Conosco</h2>
          <div className={styles['paragraphs-content']}>
            <h5 className="heading5 cyan600">
              Obrigado por seu interesse em fazer parte da Allcom Sistemas!
            </h5>
            <p className="paragraphsNormal text">
              Desde 2016 a{' '}
              <span className="paragraphsBold cyan500">Allcom Sistemas</span>,
              disponibiliza no mercado da tecnologia da informação, softwares e
              soluções de gestão para empresas, com o objetivo de agregar aos
              seus clientes produtividade, eficiência e controle.
            </p>
          </div>
        </div>
        <div className={styles['image-container']}>
          <img src={image} alt="Trabalhe Conosco" />
        </div>
      </div>
      <div className={styles['container-vagas']}>
        <h4 className="heading4">Nossas Vagas</h4>
        <p className="paragraphsNormal text">Confira nossas oportunidades!</p>
        <h4 className={classNames(styles['title-table'], 'heading4 cyan600')}>
          Desenvolvimento
        </h4>

        <Link to="/detalhes-vaga" className={styles['button-vagas']}>
          <div className={styles['row-table']}>
            <p className="paragraphsBold">Desenvolvedor back-end</p>
            <p className="paragraphsNormal">Bauru/SP</p>
            <p className="paragraphsNormal">Efetivo</p>
          </div>
        </Link>
      </div>

      <div className={styles['anexo-container']}>
        <h6 className="heading6">
          Envie seu currículo, clique no botão abaixo!
        </h6>
        <div className={styles['anexo-content']}>
          <Input
            label="Nome *"
            name="nome"
            placeholder="Seu Nome"
            value={nome}
            onChange={value => setNome(value)}
          />
          <InputFile
            label={curriculo ? 'Alterar arquivo' : 'Anexar currículo'}
            name="curriculo"
            multiple={false}
            accept=".pdf, .docx, .doc"
            onChange={value => value && setCurriculo(value[0])}
          />

          {curriculo && (
            <div>
              <p className="body1 text-light">{curriculo.name}</p>
            </div>
          )}
        </div>
        <button className={styles['button-submit']} onClick={handleSubmit}>
          {isSendingForm && (
            <CircleNotch size={18} className={styles['rotate']} />
          )}{' '}
          {isSendingForm ? 'Enviando' : 'Enviar Currículo'}
        </button>
      </div>
    </div>
  );
};
