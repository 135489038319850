import { Footer } from '../components/Footer';
import { Layout } from '../components/Layout';
import { TrabalheConoscoComponent } from '../components/sections/TrabalheConosco';

export const TrabalheConosco = () => {
  return (
    <Layout>
      <TrabalheConoscoComponent />
      <Footer />
    </Layout>
  );
};
