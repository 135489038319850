import { FormEvent, useEffect, useState } from 'react';
import styles from './DetalheVaga.module.scss';

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { toast } from 'react-toastify';

import { InputFile } from '../InputFile';
import { readFileContents } from '../../functions/readFileContents';

import iconFacebook from '../../assets/icon-facebook.svg';
import iconLinkedin from '../../assets/icon-linkedin.svg';
import iconTwitter from '../../assets/icon-twitter.svg';
import { CircleNotch } from 'phosphor-react';

export const DetalheVagaComponent = () => {
  const [hostname, setHostname] = useState('');
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [curriculo, setCurriculo] = useState<File | null>(null);

  const UPLOAD_FILE_URL =
    'https://script.google.com/macros/s/AKfycbzoMmG810zS4twEpsjftoqmOwgRHBwPEmhSDiodkq6xjoyAMLMOzySgI9zsQEgLVJg/exec';

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSendingForm(true);

    try {
      if (curriculo === null) {
        toast.warn('Anexe o currículo para enviar.', {
          position: 'bottom-right',
          pauseOnHover: false,
          theme: 'colored',
        });
        return;
      }

      const fileContents = await readFileContents(curriculo);

      const params = new URLSearchParams({
        filename: `${curriculo.name}-VAGA-BACKEND`,
        mimeType: curriculo.type,
      });

      const uploadFileResponse = await fetch(`${UPLOAD_FILE_URL}?${params}`, {
        method: 'POST',
        body: JSON.stringify([...new Int8Array(fileContents)]),
      });

      if (uploadFileResponse.status !== 200) {
        const text = await uploadFileResponse.text();
        throw new Error(`Fetch Error: Failed to upload a file. ${text}`);
      }

      toast.success('Currículo enviado com sucesso!', {
        position: 'bottom-right',
        pauseOnHover: false,
        theme: 'colored',
      });

      setCurriculo(null);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao enviar formulário.', {
        position: 'bottom-right',
        pauseOnHover: false,
        theme: 'colored',
      });
    } finally {
      setIsSendingForm(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hostname = window.location.href;

      setHostname(hostname);
    }
  }, []);

  return (
    <div className="center-container">
      <div className={styles['content']}>
        <div className={styles['title-container']}>
          <div>
            <h3 className="heading3 cyan600">Desenvolvedor Back-end</h3>
          </div>
          <div className={styles['compartilhar-container']}>
            <span className="caption1 text">Compartilhe esta vaga:</span>
            <div className={styles['icons-compartilhar-container']}>
              <FacebookShareButton
                url={hostname}
                title="Facebook"
                className={styles['icons-compartilhar']}
              >
                <img src={iconFacebook} alt="Facebook" />
              </FacebookShareButton>
              <LinkedinShareButton
                url={hostname}
                title="Linkedin"
                className={styles['icons-compartilhar']}
              >
                <img src={iconLinkedin} alt="Linkedin" />
              </LinkedinShareButton>
              <TwitterShareButton
                url={hostname}
                title="Twitter"
                className={styles['icons-compartilhar']}
              >
                <img src={iconTwitter} alt="Twitter" />
              </TwitterShareButton>
            </div>
          </div>
        </div>
        <div className={styles['descricao-container']}>
          <h6 className="heading6">Requisitos</h6>
          <ul>
            <li className="paragraphsNormal text">Git</li>
            <li className="paragraphsNormal text">GitFlow</li>
            <li className="paragraphsNormal text">Pull Requests</li>
            <li className="paragraphsNormal text">Pipelines</li>
            <li className="paragraphsNormal text">Versionamento</li>
          </ul>
          <ul>
            <li className="paragraphsNormal text">NodeJS</li>
            <li className="paragraphsNormal text">TypeScript</li>
            <li className="paragraphsNormal text">APIs</li>
          </ul>
          <ul>
            <li className="paragraphsNormal text">REST</li>
            <li className="paragraphsNormal text">GraphQL</li>
          </ul>
          <ul>
            <li className="paragraphsNormal text">Apollo Server</li>
            <li className="paragraphsNormal text">ExpressJS</li>
            <li className="paragraphsNormal text">Knex</li>
            <li className="paragraphsNormal text">JWT</li>
            <li className="paragraphsNormal text">AWS SDK</li>
            <li className="paragraphsNormal text">Jest</li>
          </ul>
          <ul>
            <li className="paragraphsNormal text">Banco de Dados</li>
            <li className="paragraphsNormal text">Postgres</li>
            <li className="paragraphsNormal text">SQL Server</li>
            <li className="paragraphsNormal text">MySQL</li>
          </ul>
          <h6 className="heading6">Diferênciais</h6>
          <ul>
            <li className="paragraphsNormal text">Bash</li>
            <li className="paragraphsNormal text">Docker</li>
          </ul>
          <h6 className="heading6">Benefícios</h6>
          <ul>
            <li className="paragraphsNormal text">Salário compatível</li>
            <li className="paragraphsNormal text">Seguro de vida</li>
            <li className="paragraphsNormal text">Vale Refeição</li>
            <li className="paragraphsNormal text">Vale Transporte</li>
            <li className="paragraphsNormal text">Plano de Saúde</li>
          </ul>
          <h6 className="heading6">Regime de Contratação</h6>
          <ul>
            <li className="paragraphsNormal text">CLT</li>
            <li className="paragraphsNormal text">PJ</li>
          </ul>
          <h6 className="heading6">
            Envie seu currículo, clique no botão abaixo!
          </h6>
        </div>
        <div className={styles['anexo-container']}>
          <InputFile
            label={curriculo ? 'Alterar arquivo' : 'Anexar currículo'}
            name="curriculo"
            multiple={false}
            accept=".pdf, .docx, .doc"
            onChange={value => value && setCurriculo(value[0])}
          />

          {curriculo && (
            <div>
              <p className="body1 text-light">{curriculo.name}</p>
            </div>
          )}
        </div>
        <button className={styles['button-submit']} onClick={handleSubmit}>
          {isSendingForm && (
            <CircleNotch size={18} className={styles['rotate']} />
          )}{' '}
          {isSendingForm ? 'Enviando' : 'Enviar Currículo'}
        </button>
      </div>
    </div>
  );
};
