import { Footer } from '../components/Footer';
import { Layout } from '../components/Layout';
import { DetalheVagaComponent } from '../components/sections/DetalheVaga';

export const DetalhesVaga = () => {
  return (
    <Layout>
      <DetalheVagaComponent />
      <Footer />
    </Layout>
  );
};
