import styles from './NotFound.module.scss';

import NotFound from '../../assets/404.svg';

export const NotFoundComponent = () => {
  return (
    <div className={styles['container']}>
      <div>
        <img width={600} height={600} src={NotFound} alt="Page Not Found" />
      </div>
    </div>
  );
};
