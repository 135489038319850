import styles from './CardDepoimentos.module.scss';

import aspas from '../assets/aspas.svg';

export interface ICardDepoimentos {
  title: string;
  subTitle: string;
  description: string;
}

export const CardDepoimentos = ({
  title,
  subTitle,
  description,
}: ICardDepoimentos) => {
  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <h4 className="heading4">{title}</h4>
        <p className="paragraphsBig text">{subTitle}</p>
        <div className={styles['description']}>
          <img src={aspas} alt={'ASPAS'} width={40} height={40} />
          <p className="paragraphsNormal text">{description}</p>
        </div>
      </div>
    </div>
  );
};
