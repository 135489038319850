import { useState } from 'react';
import { List, X } from 'phosphor-react';
import classNames from 'classnames';
import rpLogo from '../assets/allcom-logo-horizontal.svg';
import styles from './Header.module.scss';

import { BiSupport } from 'react-icons/bi';
import { Link } from 'react-router-dom';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showTooltipContato, setShowTooltipContato] = useState(false);

  return (
    <header className={styles['header']}>
      <div className="center-container">
        <div className={styles['header-navbar-container']}>
          <a href="/#inicio">
            <img src={rpLogo} alt="Allcom Sistemas" width={120} height={40} />
          </a>

          <nav
            className={classNames(
              styles['navbar'],
              isMenuOpen ? 'navbar-mobile' : styles['hide'],
            )}
          >
            <ul className={styles['navbar-list']}>
              <li>
                <a href="/#inicio">Início</a>
              </li>
              <li>
                <a href="/#nossas-solucoes">Soluções</a>
              </li>
              <li>
                <a href="/#nossos-diferenciais">Diferenciais</a>
              </li>
              <li>
                <a href="/#depoimentos">Depoimentos</a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://blog.allcomsistemas.com.br/"
                >
                  Blog
                </a>
              </li>
              <li
                onMouseEnter={() => setShowTooltipContato(true)}
                onMouseLeave={() => setShowTooltipContato(false)}
              >
                <a href="/#contato">Contato</a>
              </li>
              {showTooltipContato && (
                <div
                  className={styles['tooltip-contato']}
                  onMouseEnter={() => setShowTooltipContato(true)}
                  onMouseLeave={() => setShowTooltipContato(false)}
                >
                  <a href="/#contato">Contato</a>
                  <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
                </div>
              )}
            </ul>
          </nav>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://help.allcomsistemas.com.br"
            className={classNames(
              styles['btn-area-cliente'],
              'bg-gray200',
              'hover-bg-cyanGradients',
            )}
          >
            <span>
              <BiSupport size={18} />
              HELP DESK
            </span>
          </a>

          <button
            type="button"
            className={styles['toggle-menu-button']}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <List size={24} />}
          </button>
        </div>
      </div>
    </header>
  );
};
