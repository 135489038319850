import { FormEvent, useState } from 'react';

import * as Yup from 'yup';
import { ImSpinner2 } from 'react-icons/im';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

import classNames from 'classnames';

import { Input } from '../Input';
import { Button } from '../Button';
import { Textarea } from '../Textarea';
import { TitleSection } from '../TitleSection';
import { phoneMask } from '../../functions/phoneMask';

import styles from './Contato.module.scss';

interface Fields {
  nome: string;
  assunto: string;
  email: string;
  telefone: string;
  mensagem: string;
}

export const Contato = () => {
  const [errors, setErrors] = useState<Fields | null>();
  const [isLoading, setIsLoading] = useState(false);

  const [fields, setFields] = useState({
    nome: '',
    email: '',
    telefone: '',
    mensagem: '',
  });

  const successToast = (content: ToastContent, options?: ToastOptions) =>
    toast.success(content, {
      position: 'bottom-right',
      theme: 'colored',
      style: {
        background: '#21AA50',
      },
      ...options,
    });

  const URL =
    'https://script.google.com/macros/s/AKfycbzLBL9G66zgcbl1q1HoqPi04oRrM4rqIWkhnAooXLHFsM87uq4RyN8tAmSVn2Q_2CUqFw/exec';

  const handleSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      setErrors(null);

      const schema = Yup.object({
        nome: Yup.string().required('Nome obrigatório.'),
        email: Yup.string().required('E-mail obrigatório.'),
        telefone: Yup.string().required('Telefone obrigatório.'),
        mensagem: Yup.string().required('Mensagem obrigatório.'),
      });

      await schema.validate(fields, { abortEarly: false });

      await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          nome: fields.nome,
          email: fields.email,
          telefone: fields.telefone,
          mensagem: fields.mensagem,
        }),
      });

      setFields({
        nome: '',
        email: '',
        telefone: '',
        mensagem: '',
      });

      successToast('Enviado');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let validationErrors = {};

        err.inner.forEach(error => {
          if (error.path) {
            validationErrors = {
              ...validationErrors,
              [error.path]: error.message,
            };
          }
        });

        setErrors(validationErrors as Fields);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <section
      id="contato"
      className={classNames(styles['contato-container'], 'bg-white')}
    >
      <div className="center-container">
        <div className="center">
          <TitleSection title={'Entre em Contato'} />
          <div className={styles['contato-section']}>
            <form onSubmit={handleSubmit} className={styles['contact-form']}>
              <div className={styles['flex']}>
                <Input
                  label="Nome *"
                  name="nome"
                  placeholder="Seu nome"
                  error={errors?.nome}
                  value={fields.nome}
                  onChange={value => setFields({ ...fields, nome: value })}
                />
                <Input
                  label="Telefone *"
                  name="telefone"
                  maxLength={15}
                  placeholder="Seu telefone"
                  error={errors?.telefone}
                  value={phoneMask(fields.telefone)}
                  onChange={value =>
                    setFields({
                      ...fields,
                      telefone: value.replace(/\D+/g, ''),
                    })
                  }
                />
              </div>
              <div className={styles['flex']}>
                <Input
                  label="E-mail *"
                  name="email"
                  placeholder="Seu email"
                  error={errors?.email}
                  value={fields.email}
                  onChange={value => setFields({ ...fields, email: value })}
                />
              </div>
              <Textarea
                label="Mensagem *"
                name="mensagem"
                placeholder="Escreva a mensagem aqui"
                error={errors?.mensagem}
                value={fields.mensagem}
                onChange={value => setFields({ ...fields, mensagem: value })}
              />
              <div className={styles['recaptcha-content']}>
                <div />
                <Button
                  type="submit"
                  className={isLoading ? 'bg-gray100' : 'bg-cyanGradients'}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <ImSpinner2 className={styles['rotate']} />
                  ) : (
                    'Enviar Mensagem'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
