import { CustomArrowProps } from 'react-slick';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

export const responsive = {
  responsive: [
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 1080,
      settings: {
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const NextArrow = ({
  currentSlide,
  slideCount,
  className,
  style,
  ...props
}: CustomArrowProps) => (
  <FaChevronRight color={'#475569'} className={className} {...props} />
);

export const PrevArrow = ({
  currentSlide,
  slideCount,
  className,
  style,
  ...props
}: CustomArrowProps) => (
  <FaChevronLeft color={'#475569'} className={className} {...props} />
);
