import styles from './TitleSection.module.scss';

export interface ITitleSection {
  title: string;
}

export const TitleSection = ({ title }: ITitleSection) => {
  return (
    <div className={styles['titleContainer']}>
      <h2 className="heading2">{title}</h2>
    </div>
  );
};
