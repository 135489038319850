import styles from './CardDiferenciais.module.scss';

export interface ICardDiferenciais {
  logo: { url: string; alt: string };
  title: string;
  description: string;
}

export const CardDiferenciais = ({
  logo,
  title,
  description,
}: ICardDiferenciais) => {
  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <img src={logo.url} alt={logo.alt} width={70} height={70} />
        <div className={styles['column']}>
          <h4 className="heading4">{title}</h4>
          <p className="paragraphsNormal text">{description}</p>
        </div>
      </div>
    </div>
  );
};
