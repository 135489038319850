import { Footer } from '../components/Footer';
import { Layout } from '../components/Layout';
import { LGPDComponent } from '../components/sections/lgpd';

export const LGPD = () => {
  window.scrollTo(0, 0);

  return (
    <Layout>
      <LGPDComponent />
      <Footer />
    </Layout>
  );
};
