import styles from './PoliticaPrivacidade.module.scss';

import logo from '../../assets/allcom-logo-horizontal.svg';

export const PoliticaPrivacidadeComponent = () => {
  return (
    <section className="center-container">
      <div className={styles['title-container']}>
        <img src={logo} alt="Allcom Sistemas Logo" />
        <h2 className="heading3">Política de Privacidade</h2>
      </div>

      <div className={styles['policy-container']}>
        <p className="body1">
          A sua privacidade é importante para nós. Respeitar a sua privacidade
          em relação a qualquer informação sua que possamos coletar no site, e
          outros sites que possuímos e operamos. Solicitamos informações
          pessoais apenas quando realmente precisamos delas para lhe fornecer um
          serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e
          consentimento. Também informamos por que estamos coletando e como será
          usado. Apenas retemos as informações coletadas pelo tempo necessário
          para fornecer o serviço solicitado.
        </p>

        <p className="body1">
          Quando armazenamos dados, protegemos dentro de meios comercialmente
          aceitáveis para evitar perdas e roubos, bem como acesso, divulgação,
          cópia, uso ou modificação não autorizados. Não compartilhamos
          informações de identificação pessoal publicamente ou com terceiros,
          exceto quando exigido por lei.
        </p>

        <p className="body1">
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente de que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade por suas
          respectivas políticas de privacidade. Você é livre para recusar a
          nossa solicitação de informações pessoais, entendendo que talvez não
          possamos fornecer alguns dos serviços desejados. O uso continuado de
          nosso site será considerado como aceitação de nossas práticas em torno
          de privacidade e informações pessoais.
        </p>

        <p className="body1">
          Se você tiver alguma dúvida sobre como lidamos com dados do usuário e
          informações pessoais, entre em contacto conosco. Compromisso do
          Usuário O usuário se compromete a fazer uso adequado dos conteúdos e
          da informação que o Website e Loja Virtual 2rs oferece no site e com
          caráter enunciativo, mas não limitativo:
        </p>

        <p className="body1">
          A) Não se envolver em atividades que sejam ilegais ou contrárias à boa
          fé a à ordem pública;
        </p>

        <p className="body1">
          B) Não difundir propaganda ou conteúdo de natureza racista,
          xenofóbica, ou sobre cassinos, apostas desportivas (ex.: ), jogos de
          sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao
          terrorismo ou contra os direitos humanos;
        </p>

        <p className="body1">
          C) Não causar danos aos sistemas físicos (hardwares) e lógicos
          (softwares) do Website, de seus fornecedores ou terceiros, para
          introduzir ou disseminar vírus informáticos ou quaisquer outros
          sistemas de hardware ou software que sejam capazes de causar danos
          anteriormente mencionados.
        </p>

        <p className="body1">
          Mais informações Esperemos que esteja esclarecido e, como mencionado
          anteriormente, se houver algo que você não tem certeza se precisa ou
          não, geralmente é mais seguro deixar os cookies ativados, caso
          interaja com um dos recursos que você usa em nosso site.
        </p>
      </div>
    </section>
  );
};
