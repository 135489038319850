import styles from './CardSolucoes.module.scss';

export interface ICardSolucoes {
  logo: { url: string; alt: string };
  title: string;
  description: string;
}

export const CardSolucoes = ({ logo, title, description }: ICardSolucoes) => {
  return (
    <div className={styles['container']}>
      <img src={logo.url} alt={logo.alt} width={70} height={70} />
      <h4 className="heading4">{title}</h4>
      <p className="paragraphsNormal text">{description}</p>
    </div>
  );
};
