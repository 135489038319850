import Typewriter from 'typewriter-effect';

import { Button } from '../Button';

import styles from './Inicio.module.scss';

export const Inicio = () => {
  return (
    <section id="inicio" className={styles['inicio-section']}>
      <div className="center-container">
        <div className={styles['inicio-container']}>
          <article className={styles['inicio-description']}>
            <h1 className="heading1 title">
              <Typewriter
                options={{
                  strings: [
                    'Seja Bem Vindo!',
                    'Somos a Allcom Sistemas.',
                    'Como podemos ajudá-lo?',
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 50,
                  deleteSpeed: 100,
                }}
              />
            </h1>
            <p className="paragraphsNormal text">
              Dê um passo importante para melhorar a eficiência <br /> e os
              resultados do seu negócio.
            </p>
            <a className={styles['button']} href="/#contato">
              ENTRAR EM CONTATO
            </a>
          </article>
        </div>
      </div>
    </section>
  );
};
