import { ChangeEvent, InputHTMLAttributes } from 'react';

import { ErrorMessage } from './ErrorMessage';

import styles from './Input.module.scss';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string;
  error?: string;
  onChange: (value: string, e: ChangeEvent) => void;
}

export const Input: React.FC<InputProps> = ({
  label,
  name,
  error,
  placeholder,
  onChange,
  ...rest
}) => {
  return (
    <section className={styles['input-section']}>
      <input
        id={name}
        name={name}
        type="text"
        placeholder={placeholder}
        className={styles['input-field']}
        onChange={e => onChange(e.target.value, e)}
        {...rest}
      />
      {error && <ErrorMessage error={error} />}
    </section>
  );
};
