import styles from './NossasSolucoes.module.scss';

import { Carousel } from '../Carrousel';
import { CardSolucoes } from '../CardSolucoes';
import { TitleSection } from '../TitleSection';

import sgvLogo from '../../assets/icons-solucoes/sgv-logo.png';
import i9crmLogo from '../../assets/icons-solucoes/logo-i9crm.svg';
import sgvpmLogo from '../../assets/icons-solucoes/sgvpm-logo.png';
import uniqueLogo from '../../assets/icons-solucoes/unique-logo.png';
import helpgoLogo from '../../assets/icons-solucoes/helpgo-logo.svg';
import wallcobLogo from '../../assets/icons-solucoes/wallcob-logo.svg';
import projetosLogo from '../../assets/icons-solucoes/projetos-logo.svg';

export const NossasSolucoes = () => {
  return (
    <section id="nossas-solucoes" className={styles['solucoes-container']}>
      <div className="center-container">
        <div className="center">
          <TitleSection title={'Nossas Soluções'} />
          <div className={styles['solucoes-section']}>
            <Carousel>
              <CardSolucoes
                logo={{
                  url: sgvLogo,
                  alt: 'SGV',
                }}
                title="SGV"
                description="Controle suas vendas, em uma plataforma que fornece: Auditoria,
                  qualidade e módulo mobile com a mais alta performance e qualidade disponíveis no mercado."
              />
              <CardSolucoes
                logo={{
                  url: uniqueLogo,
                  alt: 'Unique',
                }}
                title="Unique"
                description="Controle os processos de sua empresa, automatizando e integrando
                  atividades financeiras, vendas, contabilidade, estoque, compras,
                  RH, produção e logística e relatórios."
              />
              <CardSolucoes
                logo={{
                  url: wallcobLogo,
                  alt: 'Wallcob',
                }}
                title="Wallcob"
                description="O WallCob foi desenvolvido ao longo de vários anos sob
                  demandas reais do cotidiano da recuperação de crédito e engloba
                  todas as etapas de cobrança – da importação de carteiras ao
                  envio de boletos."
              />
              <CardSolucoes
                logo={{
                  url: sgvpmLogo,
                  alt: 'SGVPM',
                }}
                title="SGVPM"
                description="Sistema de gestão de viveiros e produção de mudas. Controle seu
                  agronegócio, oferecemos: Controle de produção, gestão de vendas,
                  efetividade de plantio, mudas disponíveis com um report mensal."
              />
              <CardSolucoes
                logo={{
                  url: i9crmLogo,
                  alt: 'I9CRM',
                }}
                title="i9CRM"
                description="O i9CRM é um aplicativo CRM desenvolvido especialmente para parceiros de vendas PAP. Com ele é possível organizar, distribuir, qualificar, monitorar, aumentar a produtividade e muito mais, através da padronização de tarefas de cadastros, controles e apontamento, centralizando as informações em um banco único."
              />
              <CardSolucoes
                logo={{
                  url: helpgoLogo,
                  alt: 'HelpGo',
                }}
                title="HelpGo"
                description="O HelpGO é uma plataforma online que permite que sua empresa
                  organize os chamados de suporte (help desk) e atendimentos com
                  seus clientes em apenas um lugar, além de controlar horas
                  trabalhas e conta com um controle financeiro."
              />
              <CardSolucoes
                logo={{
                  url: projetosLogo,
                  alt: 'Projetos de TI',
                }}
                title="Projetos de TI"
                description="A gestão de projetos de TI administra estrategicamente o negócio
                  para garantir que as demandas e o fluxo de trabalho estejam
                  alinhados com as necessidades, planejamentos e cronogramas da
                  empresa. Trazendo os melhores resultados para seu negócio."
              />
            </Carousel>
          </div>
          <a className={styles['button']} href="/#contato">
            SOLICITE UMA DEMONSTRAÇÃO
          </a>
        </div>
      </div>
    </section>
  );
};
