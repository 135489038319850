import Slider from 'react-slick';

import { TitleSection } from '../TitleSection';
import { CardDepoimentos } from '../CardDepoimentos';
import {
  NextArrow,
  PrevArrow,
  responsive,
} from '../../constants/constants-carrousel';
import depoimentos from '../../constants/depoimentos.json';

import styles from './Depoimentos.module.scss';

export const Depoimentos = () => {
  return (
    <section id="depoimentos" className={styles['depoimentos-container']}>
      <div className="center-container">
        <div className="center">
          <TitleSection title={'Depoimentos'} />
          <div className={styles['depoimentos-section']}>
            <Slider
              {...responsive}
              autoplay={true}
              pauseOnHover={true}
              infinite={true}
              speed={800}
              slidesPerRow={1}
              slidesToShow={2}
              slidesToScroll={1}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {depoimentos.map((depoimento, index) => (
                <CardDepoimentos
                  key={index}
                  title={depoimento.title}
                  subTitle={depoimento.subTitle}
                  description={depoimento.description}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
